<template>

	  <v-dialog
		v-model="dialog"
		persistent
		width="900px">	  

    <v-card class="modal-con update-modal" @dragover.prevent @drop.prevent="handleDrop">

          <v-card-title class="p-0 modal-title-con">
            <h5 class="modal-title">Upload File</h5>
          </v-card-title>
          <button type="button"
              class="close-btn-icon"
              @click="close"
            >
            <span class="outline">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            <span class="outline_hover">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            </button>
          <v-card-text class="modal-content-con p-0">	

                <div class="file-upload-modal">
                  <div class="file-import">
                    <div class="drop-area">
                      <input ref="fileInput" type="file" style="display:none" @change="handleFileInput" multiple>
                      Drag and Drop CSV file or click to select a file.
                    </div>
                    <div>
                      <span @click="handleClick">
                        <UploadIcon lass="table-action icon-btn"/>
                      </span>
                    </div>
                  </div>
                </div>

                <ul class="csv-files-list">
                  <li v-for="(file, index) in files" :key="index">
                    <span>{{ file.name }}</span>
                    <RemoveFile class="remove-file" @click="removeFile(index)"/>
                  </li>
                </ul>
              <InfoPanel v-if="!showError" :isClose="false">
                <div class="d-flex csv_text flex-column">
                <p>1. Make sure your CSV file has 4 column headers - FirstName - LastName - PhoneNumber - Email</p>
                <p>2. Check that all column rows have data, and that phone numbers are properly formatted.</p>
                </div>
              </InfoPanel>
              <div class="import_error" v-if="showError">
                <p>Attention</p>
                <p>{{errorMessage}}</p>
              </div>
               
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :ripple="false"
                  color="blue-darken-1"
                  class="close-btn text-btn btn"
                  variant="text"
                  @click="close"
                >
                    CANCEL
                </v-btn>
                <v-btn
                  :ripple="false"
                  color="blue-darken-1"
                  :class="{ 'disabled': files.length == 0 }"
                  variant="text"
                  @click="uploadFile"
                >
                  SUBMIT
                </v-btn>
              </v-card-actions>
              
              </v-card-text>
    </v-card>
    
  </v-dialog>

</template>

<script>
import { ref, defineEmits } from 'vue';
import { VDialog, VCard, VCardActions } from 'vuetify';
import UploadIcon from '@/components/icons/UploadIcon.vue';
import RemoveFile from '@/components/icons/RemoveFile.vue';
import InfoPanel from '@/components/common/InfoPanel.vue';
import axios from 'axios';

const API_URL = process.env.VUE_APP_APIDOMAIN + "/uploadCsv";

export default {
  name: 'ImportCsv',
   setup(){
        const imit = defineEmits(['closed'])
    },
  components: {
    VDialog,
    VCard,
    VCardActions,
    UploadIcon,
    RemoveFile,
    InfoPanel
  },
  data() {
    return {
      files: [],
      token: localStorage.getItem("tknds") || '',
      csvData: [],
      showError: false,
      errorMessage: 'Importing this CSV file cannot be completed. The formatting is incorrect.'
    };
  },
  computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('closed', value);
            }
        }
    },

  methods: {
    close() {
      this.dialog = false;
      this.files = [];
    },
    handleClick() {
      this.$refs.fileInput.click();
    },
    handleFileInput(event) {
      const inputFiles = event.target.files;
      this.handleFiles(inputFiles);
    },
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type === 'text/csv') {
          this.files.push(file);
        } else {
          alert('Please upload only CSV files.');
        }
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    handleDrop(event) {
      const droppedFiles = event.dataTransfer.files;
      this.handleFiles(droppedFiles);
    },
   
   uploadFile() {
    var isValid = true;
    // Check if there are any files to upload
    if (this.files.length === 0) {
        alert('Please select a CSV file.');
        return;
    }

    let fileIndex = 0;
    let lineIndex = 1;
    this.showError = false
    const processFile = () => {
        const file = this.files[fileIndex];
        const reader = new FileReader();
        reader.onload = () => {
            const csvData = reader.result.trim();

            // Parse CSV data
            const lines = csvData.split('\n');
            let breakOuterLoop = false;
            for (let j = lineIndex; j < lines.length; j++) {
                const columns = lines[j].split(',');
                //alert(columns)
                // Check if required fields are empty
                if (columns.length !== 4) {
                  this.showError = true
                  this.errorMessage = 'Invalid CSV format. Please ensure the CSV file has exactly 4 columns (FirstName, LastName, PhoneNumber, Email) per row.'
                 
                    isValid = false;
                    breakOuterLoop = true;
                    return; // Exit the inner loop
                } 
                const firstName = columns[0].trim();
                const lastName = columns[1].trim();
                const phoneNumber = columns[2].trim();
                const email = columns[3].trim();

                // Check if any required field is empty
                if (firstName === '' || lastName === '' || phoneNumber === '' || email === '') {
                   // alert('Please ensure all fields (FirstName, LastName, PhoneNumber, Email) are not empty.');
                    isValid = false;
                    this.showError = true
                    setTimeout(()=>{ this.showError = false},5000)
                    breakOuterLoop = true;
                    return; // Exit the inner loop
                }
                this.csvData.push({'firstName':firstName, 'lastName':lastName, 'phoneNumber': phoneNumber, 'email':email});
            }
            // Process next file or submit form
            lineIndex = 1; // Reset line index for next file
            fileIndex++;
            if (fileIndex < this.files.length) {
                processFile();
            } else {
                // Submit form if all files are processed and isValid is true
                if (isValid) {
                    console.log('this.csvData :: ', this.csvData)
                    this.submitForm();
                }
            }
        };
        reader.readAsText(file);
    };
    processFile();
},

submitForm() {
    const formData = new FormData();
    this.files.forEach(file => {
        formData.append('csvFiles', file);
    });
    //alert('here')
    this.$emit('message', this.csvData);
    this.dialog = false;
    this.files = [];
    /* axios.post(API_URL, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
        }
    }).then(response => {
        console.log(response.data);
        this.dialog = false;
        this.$emit('message');
        this.files='';
        // Handle response
    }).catch(error => {
        console.error(error);
        // Handle error
    }); */
}

  }
};
</script>
<style scoped lang="scss">
  .v-card-actions {
    margin-top: 20px !important;
    padding: 0 !important;
    margin-right: -5px;
    margin-bottom: -6px;
  }
</style>

<style lang="scss">
.import_error{
  height: 80px;
    padding-left:19px;
  padding-right:19px;
  padding-top: 15px;
  border-radius: 10px;
background: #F0D3D3;
  padding-bottom: 15px;
    p{
    font-weight: 400;
   line-height: 24px;

  }
}
.csv_text{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-left:3px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.file-upload-modal .file-import {
  margin-top: 39px;
  height: 162px;
  padding: 34px 30px;
  background: #F2F2F2;
  border: 2px dashed #BDBDBD;
  /* border-radius: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 54px;
  cursor: pointer;
}

.drop-area{
  font-size: 16px;
  font-weight: 500;
  color: #828282;
}

.v-card-actions {
  margin-top: 10px !important;
}

.v-card-actions .text-btn {
  color: #2F80ED;
}

.csv-files-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top:24px;
  gap: 10px;
  padding:0px ;
}

.csv-files-list li {
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
  border: 2px solid #7B8794;
  background: #F2F2F2;
  color: #7B8794;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.csv-files-list li .remove-file {
    cursor: pointer;
}

</style>
