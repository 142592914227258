<template>
  <div class="group-container mb-10">
    <!-- <DemoTable /> -->
    <v-row class="align-stretch w-100">
      <v-col class="modal-col" cols="12" sm="6" md="6">
        <div class="group_list_section white position-relative">
          <div class="d-flex contacts-search justify-space-between"></div>
          <div class="align-center justify-center table-s2g-wrapper">
            <S2gTable
              :indeterminate="indeterminate"
              :items="contacts"
              :selectedItemCount="selectedContacts"
              :headers="contactheader"
              :filterItems="[
                { label: 'All', value: 'all' },
                { label: 'None', value: 'none' },
              ]"
              @column-filter="handleFilter"
              @toggle-row-select="selectMembers"
              :thsearch="true"
              :isReload="false"
              @search="search"
              :totalItems="`${pager.totalItems}`"
              :selectAllCbDisabled="noContacts"
              :disabledColumn="noContacts ? {'first_col':'true'} : {}"
              :totalCountText="'Contacts'"
              :showCount="true"
              :isSelectGlobalCheckBox="isSelectAll"
              @select-all="selectAll"
              :page="(currentPage - 1) * itemsPerPage"
            >
            
              <template v-slot:table_body="{ item }">
                <td>
                  <span
                    >{{ item.item.FirstName }} {{ item.item.LastName }}</span
                  >
                </td>
              </template>
            </S2gTable>
          </div>
          <div
            v-if="noContacts"
            class="align-center contacts__info justify-center"
          >
            <p>
              Creating a group requires adding contacts first. Go to the
              Contacts page and add the people you want to receive messages.
            </p>
          </div>
          
          <div class="d-flex add_to_group justify-space-between align-items-center">
            <div>
              <div class="succes_message" v-show="apiSuccess">
                <p>Contacts added</p>
              </div>
            </div>
            <v-btn
              class="btn-outline2"
              :class="{
                'btn-outline2-disabled': selectedContacts == 0 || isActiveGroupSection,
              }"
              append-icon="mdi-chevron-double-right"
              @click="addToGroup()"
            >
              Add to group
            </v-btn>
          </div>
        </div>
        <div class="ma-10" :class="{ disabled: isActiveGroupSection }">
          <Paginaiton
            v-if="pager.totalPages > 1"
            :currentPage="currentPage"
            :totalItems="pager.totalItems"
            :itemsPerPage="itemsPerPage"
            :totalPages="pager.totalPages"
            @page-changed="changePage"
          />
        </div>
      </v-col>
      <v-col class="modal-col pb-0" cols="12" sm="6" md="6">
        <div class="group_section">
          <v-row>
            <v-col cols="4">
              <div class="text-center mt-2 d-flex align-items-center justify-content-center">
                <MemberIcon />
                <label class="fw-500 mx-3"> Member of </label>
              </div>
            </v-col>
            <v-col cols="4">
              <div
                class="group_type_list"
                :class="[!isActiveGroupSection ? 'disabled' : '']"
              >
                <Dropdown
                  :disabled="!isActiveGroupSection"
                  :items="groupTypesList"
                  :value="selectGroupTypeValue"
                  @item-selected="selectGroupType"
                />
              </div>
            </v-col>
            <v-col cols="4">
              <button
                v-if="isActiveGroupSection && groupData.groupType == ''"
                class="select_group_type w-100"
              >
                Select a group type.
              </button>
            </v-col>
          </v-row>
          <v-row   class="align-center justify-between my-0">
            <v-col cols="4">
              <v-sheet>
                <div class="members">
                  <h1 class="fs-128">
                    {{ selectedContacts }}
                  </h1>
                  <!-- <h1 class="fs-128" v-if="!isActiveGroupSection">0</h1> -->
                </div>
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet class="pa-2 ma-2">
                <v-form class="position-relative" fast-fail @submit.prevent>
                  <!-- <label
                    class="input_label"
                    :class="{
                      disabled_label: !isActiveGroupSection,
                    }"
                    >Label</label
                  > -->
                  <v-text-field
                    :disabled="
                      !isActiveGroupSection || groupData.groupType == ''
                    "
                    label="Group Name"
                    v-model="groupData.name"
                    :rules="groupNameRules"
                    placeholder="Name*"
                  ></v-text-field>
                </v-form>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row  class="group_footer align-center justify-space-between">
            <v-col cols="4">
              <div
              :class="{
                disabled: !isActiveGroupSection,
              }"
              class="text-center"
            >
              <span
                class="cursor_pointer"
                v-if="isActiveGroupSection"
                @click="openSelectedContactPopup"
              >
                <edit-icon></edit-icon>
              </span>
            </div>
            </v-col>
            <v-col cols="8">
              <div class="d-flex align-end justify-end">
              <div>
                <v-btn
                  class="light-btn"
                  :class="{
                    'btn-disabled-cancel': !isActiveGroupSection,
                  }"
                  @click="reset"
                >
                  CANCEL
                </v-btn>

                <v-btn
                  @click="addGroup"
                  class="btn-outline3"
                  :loading="loading"
                  :class="{
                    'btn-disabled':
                      !isActiveGroupSection || groupData.groupType == '',
                    'btn-disabled':
                      groupData.name == '' || groupData.groupType == '',
                  }"
                >
                  ADD NEW GROUP
                </v-btn>
              </div>
            </div>
            </v-col>
            
            
          </v-row>
        </div>
        <v-row class="align-stretch group_list">
          <v-col class="modal-col groups" cols="12" sm="12" md="12">
            <div class="" v-if="apiLoader.type">
              <GroupList ref="groupList" :groupTypesList="groupTypesList" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="editContactSelection" max-width="600" persistent>
      <v-card>
        <div class="pa-6">
          <contact-members
            :contacts="selectedContactItems"
            @update-checked="updateSelectMembers"
          ></contact-members>
          <Paginaiton
            v-if="editContact.pager.totalPages > 1"
            :currentPage="editContact.currentPage"
            :totalItems="editContact.pager.totalItems"
            :itemsPerPage="editContact.itemsPerPage"
            :totalPages="editContact.pager.totalPages"
            @page-changed="changePageEdit"
          />
        </div>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateSelected()"> Save </v-btn>
          <v-btn @click="editContactSelection = false"> Cancel </v-btn>
        </template>
      </v-card>
    </v-dialog>
    <GroupMemberDialog
      @close-dialog="closeDialog"
      :defaultGroupMembers="groupMembers"
      :isGroupEdit="false"
      :disableTabs="{ contacts: true, group: true }"
      v-if="groupDialog"
    />
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
import Paginaiton from "../../components/common/Paginaiton.vue";
import Switch from "../../components/common/Switch.vue";
import EditIcon from "../../components/icons/EditIcon";
import MemberIcon from "../../components/icons/MemberIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import Dropdown from "../../components/common/Dropdown.vue";

import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";
import ContactMembers from "@/components/group/ContactMembers.vue";
import GroupList from "@/components/group/GroupList.vue";
import S2gTable from "@/components/common/S2gTable.vue";
import GroupMemberDialog from "@/components/group/GroupMemberDialog.vue";

/** */
/** */
// import DemoTable from "@/components/DemoTable.vue";
export default {
  inject: ["eventBus"],
  components: {
    ContactMembers,
    Paginaiton,
    EditIcon,
    Dropdown,
    S2gTable,
    // DemoTable,
    GroupList,
    MemberIcon,
    GroupMemberDialog,
  },
  computed: {
    isActiveGroupSection() {
      return this.activateGroups;
    },

  },
  data: () => ({
    globalSelectCheckbox: false,
    contactheader: [{ key: "", title: "", class: "pr-0" }],
    indeterminate: false,
    selectAllContact: false,
    apiSuccess: false,
    isSelectAll: false,
    loading: false,
    dialog: false,
    groupDialog: false,
    confirmDialog: false,
    editContactSelection: false,
    isPartialSelect: false,
    activateGroups: false,
    noContacts: false,
    timer: false,
    user: {},
    searchMemberText: "",
    contacts: [],
    groupMember: [],
    groups: [],
    groupData: {
      groupType: "",
      name: "",
    },
    groupTypesList: [],
    member_id: 0,
    selectGroupTypeValue: "",
    currentPage: 1,
    pager: { totalPages: 0, totalItems: 0 },
    itemsPerPage: 10,
    token: localStorage.getItem("tknds") || "",
    userid: 0,
    selectedContacts: 0,
    selectedContactItems: [],
    tab: null,
    apiLoader: { type: false },
    uncheckedContacts: [],
    checkedContacts: [],
    editContact: {
      currentPage: 1,
      pager: { totalPages: 0, totalItems: 0 },
      itemsPerPage: 10,
    },
  }),
  methods: {
    closeDialog() {
      this.groupDialog = false;
      this.$refs.groupList.getGroups();
    },
    reset() {
      this.groupData = {
        groupType: "",
        name: "",
      };
      this.uncheckedContacts = [];
      this.checkedContacts = [];
      this.groupMember = [];
      this.selectedContacts = 0;
      this.selectedContactItems = [];
      this.selectAllContact = false;
      this.isSelectAll = false;
      this.activateGroups = false;
      this.indeterminate = false;
      this.contacts = this.contacts.map((o) => {
        o.checked = false;
        o.class = "hover_item";
        o.checkdClass = "";
        return o;
      });
      this.setGroupType();
    },
    search(val) { 
      this.reset();
      this.searchMemberText = val;
      this.filterPaginateGroup();
    },
    selectGroupType(e) {
      console.log(e);
      this.groupData.groupType = e.value;
      this.selectGroupTypeValue = e.label;
    },
    handleFilter(e) {
      const event = e.e;
      if (event.value == "all") {
        this.selectAll({ target: { checked: true } });
      } else {
        this.selectAll({ target: { checked: false } });
      }
    },
    handleChecked(e) {
      console.log(e);
    },
    toggleRowChecked(e) {
      console.log(e);
      const event = e.e;
      if (this.isSelectAll && !event.target.checked) {
        this.indeterminate = true;
      } else {
        const u = this.groups.every((r) => r.checked);
        console.log(u);
        this.indeterminate = false;
      }
    },
    reloadTableData(e) {
      console.log(e);
    },
    selectAll(event) {
      this.isSelectAll = event.target.checked;
      this.globalSelectCheckbox = event.target.checked;
      this.checkedContacts = [];
      this.uncheckedContacts = [];
      this.contacts = this.contacts.map((e) => {
        e.checked = event.target.checked;
        e.class = "hover_item";
        e.checkdClass = "";
        if (e.checked) {
          e.checkdClass = "member_selected";
        }
        return e;
      });

      this.activateGroups = false;

      if (this.isSelectAll) {
        this.indeterminate = false;
        this.selectedContacts = this.pager.totalItems;
        this.checkedContacts = [];
        this.uncheckedContacts = [];
      } else {
        this.selectedContacts = 0;
        this.indeterminate = false;
        this.activateGroups = false;
        this.contacts.forEach((e) => {
          e.checkdClass = "";
        });
      }
      this.contacts = this.contacts.map((e) => {
        e.checkdClass = "";
        if (e.checked) {
          e.checkdClass = "member_selected";
        }
        e.class = "hover_item";
        return e;
      });
      console.log(this.selectedContacts, event.target.checked);
    },
    selectMembers(e) {
      let event = e.e;
      this.activateGroups = false;
      //update checked property
      const u = manageSingleChecked(
        event,
        e.item,
        "UserID",
        this.contacts,
        this.selectedContacts,
        this.isSelectAll,
        this.pager.totalItems
      );

      this.selectedContacts = u.selectedCounts;
      this.indeterminate = u.indeterminate;
      this.isSelectAll = u.isSelectAll;
      manageSelectedItems(event, e.item, "UserID", this.checkedContacts);
      manageUnSelectedItems(event, e.item, "UserID", this.uncheckedContacts);
      this.contacts = this.contacts.map((e) => {
        e.checkdClass = "";
        if (e.checked) {
          e.checkdClass = "member_selected";
        }
        e.class = "hover_item";
        return e;
      });
    },
    openSelectedContactPopup() {
      const selectedM = this.checkedContacts.map((e) => e.UserID);
      const uselectedM = this.uncheckedContacts.map((e) => e.UserID);
      this.groupMembers = { selectedM, uselectedM };
      console.log(this.groupMember);
      this.groupDialog = true;
 
    },
    updateSelected() {
      this.contacts = this.contacts.map((e) => {
        const u = this.selectedContactItems.find((d) => d.UserID === e.UserID);
        if (u) {
          e.checked = u.checked;
        }
        e.class = "hover_item";
        return e;
      });
      this.editContactSelection = false;
    },
    addToGroup() {
      this.activateGroups = true;
      this.contacts.forEach((e) => {
        if (e.checked) {
          e.checkdClass = "disabled";
        }
      });
    },
    addGroup() {
      this.loading = true;

      fetch(API_URL + "groups", {
        method: "POST",
        body: JSON.stringify({
          selectedContacts: this.checkedContacts.map((e) => e.UserID),
          unselectedContacts: this.uncheckedContacts.map((e) => e.UserID),
          ...this.groupData,
          user_id: this.userid,
          isSelectAll: this.globalSelectCheckbox,
          ClientID: this.user.ClientID,
          CreatedBy: this.$root.userdetail.user[0].UserID
        }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.apiSuccess = true;
          this.loading = false;
          this.globalSelectCheckbox = false;
          this.isActiveGroupSection = false;
          this.reset();
          setTimeout((e) => {
            this.apiSuccess = false;
          }, 5000);
          this.$refs.groupList.getGroups();
        })
        .catch((e) => {
          console.log(e);
        });
      console.log("pppwse", this.groupData);
    },
    getGroupTypes() {
      fetch(API_URL + "group_type", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("groupTypesList", result);
          this.groupTypesList = result.map((e) => {
            return { label: e.Name, value: e.Id, isIcon: true, icon: e.Icon };
          });
          this.groupTypesList.unshift({
            label: "Select",
            value: "",
            isIcon: false,
            show: true,
          });
          this.setGroupType();
        });
      setTimeout((e) => {
        this.apiLoader.type = true;
      }, 1000);
    },
    setGroupType() {
      const gt = this.groupTypesList.find((e) => e.label == "Select");
      console.log("groupTypesList", this.groupTypesList);

      this.groupData.groupType = gt.value;
      this.selectGroupTypeValue = gt.label;
    },

    getContacts() {
      let url =
        API_URL +
        "teams/?page=" +
        this.currentPage +
        "&perpage=" +
        this.itemsPerPage +
        "&sort=none" +
        "&order=asc" +
        "&search=" +
        this.searchMemberText;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      //alert(url)
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            try {
              console.log('Docus :: ', result.teams.docs)
              this.contacts = result.teams.docs?.map((e) => {
                e.checked = manageRowDefaultSelection(
                  this.isSelectAll,
                  e,
                  this.checkedContacts,
                  this.uncheckedContacts,
                  "UserID"
                );
                e.class = "hover_item";
                e.checkdClass = "";
                if (e.checked) {
                  e.checkdClass = "member_selected";
                }
                return e;
              });
            } catch (e) {
              console.log(e);
            }
            if (this.contacts.length == 0) {
              this.noContacts = true;
            }
            this.pager = result.teams.pager;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    filterPaginateGroup() {
      let url =
        API_URL +
        "teams/?page=" +
        this.currentPage +
        "&perpage=" +
        this.itemsPerPage +
         "&sort=none" +
         "&order=asc" +

        "&search=" +
        
        this.searchMemberText;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            console.log(this.checkedContacts, this.uncheckedContacts);
            this.contacts = result.teams.docs?.map((e) => {
              e.checked = manageRowDefaultSelection(
                this.isSelectAll,
                e,
                this.checkedContacts,
                this.uncheckedContacts,
                "UserID"
              );
              e.class = "hover_item";
              e.checkdClass = "";
              if (e.checked) {
                e.checkdClass = "member_selected";
              }
              return e;
            });
            this.pager = result.teams.pager;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    filterPaginateGroupEdit() {
      let url =
        API_URL +
        "teams/?page=" +
        this.currentPage +
        "&perpage=" +
        this.itemsPerPage +
         "&sort=none" +
         "&order=asc" +
        "&search=" +
        this.searchMemberText;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.$root.logout();
          } else {
            this.selectedContactItems = result.teams.docs?.map((e) => {
              //e.checked = this.isSelectAll;
              return e;
            });
            this.editContact = result.teams.pager;
          }
          //this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.filterPaginateGroup(); 
    },
    changePageEdit(page) {
      this.editContact.currentPage = page;
      this.filterPaginateGroupEdit();
    },
    selectContacts(e) { 
      this.contacts = this.contacts.map((o) => {
        o.checked = e.target.checked;
        return o;
      });
    },
    searchContacts() {
      clearTimeout(this.timer);
      // delay new call 500ms
      this.reset();
      this.timer = setTimeout(() => {
        this.filterPaginateGroup();
      }, 500);
    },

    updateSelectMembers(e) { 
      this.selectedContactItems = this.selectedContactItems.map((element) => {
        if (e.item.UserID === element.UserID) {
          element.checked = e.e.target.checked;
        }
        return element;
      }); 
      this.$forceUpdate();
    },
  },

  mounted() {
    window.pageTitle = "Teams";
    this.user.Locale = "English";

    if (this.$root.userdetail.firsttimeuser) {
      localStorage.setItem("justLoggedIn", "false");
      //this.popupTriggers.buttonTrigger = this.$root.userdetail.firsttimeuser;
    }
    this.firstlogin = this.$root.userdetail.firsttimeuser; 
    this.user.ClientID = this.$root.userdetail.user[0].ClientID;
    this.userid = this.$root.userdetail.user[0].UserID;
    this.getGroupTypes();
    this.getContacts();
    this.eventBus.on("remove-group-member", (data) => {
      console.log("Event received:", data, data.selectedMember);
      if (!this.isSelectAll) {
        this.checkedContacts = this.checkedContacts.filter((e) => {
          console.log(e);
          return data.selectedMember.indexOf(e.UserID) == -1;
        });
      } else {
        this.uncheckedContacts = data.selectedMember.map((e) => {
          return { UserID: e };
        });
      }
      this.selectedContacts =
        this.selectedContacts - data.selectedMember.length;
      this.contacts = this.contacts.map((w) => {
        // return data.unselectedMember.indexOf(e.UserID)==-1
        const u = data.selectedMember.find((o) => o == w.UserID);
        if (u) {
          w.checked = false;
        }
        w.class = "hover_item";
        return w;
      });
      if (data.selectedMember.length > 0) {
        this.isSelectAll = false;
        this.indeterminate = true;
      }
      if (data.isSelectAll) {
        this.isSelectAll = false;
        this.selectAll({ target: { checked: false } });
      }
      if (
        this.pager.totalItems == data.selectedMember.length ||
        this.checkedContacts == 0
      ) {
        this.isActiveGroupSection = true;
      }
      console.log(this.checkedContacts, this.uncheckedContacts);
    });
    //
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.btn-outline2 {
  height: 40px;
  box-shadow: none;
  border-radius: 7px;
  border: 1px solid #4066cf;
  background-color: #4066cf;
  text-transform: none;
  color: #fff;
  &.btn-outline2-disabled {
    color: #000;
    border: 1px solid #adadad;
    background-color: white;
    opacity: 0.5;
    pointer-events: none;
  }
}
.group-container {
  .contacts {
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    &__info {
      background: #d6fcc4;
      border-radius: 10px;
      padding: 15px 19px;
      margin: 49px 0 0;
    }
  }

  .modal-col {
    padding-left: 16.5px;
    padding-right: 16.5px;
  }
  .group_list_section {
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    min-height: 306px;
    display: flex;
    flex-direction: column;
   
  }

  .table-s2g-wrapper {
    ::v-deep {
    & .s2g-common-table {
      margin-bottom: 0 !important;
    }
    }
  }
  .group_section {
   
    background: #fff;
    border-radius: 20px;
    padding: 20px 30px 30px;
    .members {
      display: flex;
      width: 100%;
    height: 157px;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      .fs-128 {
        color: #000;
        text-align: center;
        //font-family: Roboto;
        font-size: 128px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px; /* 25% */
      }
      ::v-deep {
        .v-input {
          &--disabled {
            border-image: none !important;
          }
        }
      }
    }
    ::v-deep {
      .v-input--disabled {
        .v-field__outline {
          &:before {
            border-image: none;
            border-color: #49454F;
          }
        }
      }
    }
  }

  ::v-deep {
    .select-bdr {
      .v-field {
        &__outline {
          &:before,
          &:after {
            border: none !important;
          }
        }
      }
      .v-field {
        &--appended {
          padding: 0 !important;
        }
      }
    }
    .group_input {
      .v-input__control {
        position: relative !important;
        top: -16px;
      }
    }

    .v-field {
      &__input {
        padding-left: 0;
      }
      &__appended {
        padding-right: 0;
      }
    }

    .serach-box {
      border-bottom: 2px solid #000000;
      height: 50px;
      margin-bottom: 40px;
      span {
        color: #7b8794;
      }
      .v-label {
        color: #000000 !important;
      }
    }
  }

  ::v-deep {
    .v-field-label {
      padding: 0;
      margin: 0;
    }
    .contacts-search {
      background: #fff;
      border-radius: 20px;
      .contact-list {
        border-bottom: 2px solid #000000;
        height: 40px;
      }
    }
  }

  .select-bdr {
    width: 120px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid var(--Gray-4, #bdbdbd);
    padding: 10px 7px 10px 14px;
  }
  .btn-gray {
    color: var(--Sig2-White, #fff);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    padding: 10px 17px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: var(--Gray-4, #bdbdbd);
  }
  .light-btn {
    color: var(--Gray-3, #828282);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    box-shadow: none !important;
  }
  .btn-outline3 {
    border-radius: 5px;
    color: var(--Gray-1, #fff);
    background: var(--Sig2-White, #4066cf);

    border: 1px solid #4066cf;
    text-transform: none;
    text-align: right;
    /* M3/label/large */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    height: 40px;
    box-shadow: none;
    &:hover {
      border: 1px solid #6b87d4;
      background: #6b87d4;
      color: #fff;
    }
  }
  .btn-disabled {
    background: #bdbdbd;
    pointer-events: none;
    // opacity: 0.4;
    border-color: #bdbdbd;
    color: #fff;
  }
  .btn-disabled-cancel {
    pointer-events: none;
    border: none;
    color: #828282;
  }
  .member_counts {
    height: 40px;
    border-bottom: 2px solid #000;
    ::v-deep {
      .v-input__details {
        display: none;
      }
    }
  }
}

.fw-500 {
  font-weight: 500;
}
.edit_btns {
  position: relative;
  left: 6em;
}
.bg-transparents {
  background-color: #f2f2f2 !important;
}
.disabled {
  background: transparent !important;
  pointer-events: none;
  opacity: 0.4;
  color: var(--Gray-1, #333);
}
.group-member {
  &__list {
    padding-left: 170px;
    padding-right: 170px;
    ::v-deep {
      .v-slide-group {
        margin-bottom: 2em;
      }
    }
  }
  &__count {
    position: relative;
    top: 25px;
    left: 15px;
    color: #000;
    font-weight: 500;
    display: inline-block !important;
  }
}

.custom-btn {
  ::v-deep {
    .v-btn__content {
      color: #000 !important;
      padding: 0px !important;
    }
    .v-tab__slider {
      background-color: #000000 !important;
    }
    .v-tab.v-tab.v-btn {
      margin-top: 0px !important;
    }
  }
}
.search-box2 {
  ::v-deep {
    .v-field__outline,
    .v-input__details {
      display: none !important;
    }
  }
}
.groups {
  &__list {
    th,
    td {
      font-size: 16px;
      color: #000;
    }
    ::v-deep {
      &.v-table {
        thead > tr > th {
          border-bottom: 1px solid #000 !important;
        }
      }
    }
  }
}

.group_type_list {
  margin-left: 17px;
  ::v-deep {
    .form-control {
      padding: 5px 13px !important;
      height: 44px !important;
      border-radius: 5px;
      border: 1px solid #bdbdbd !important;
      //background: #fff;
      position: relative;
      z-index: 1 !important;
    }
  }
}
.input_label {
  font-size: 12px;
  color: #49454f;
  position: absolute;
  top: -8px;
  &.disabled_label {
    opacity: 0.5;
  }
}
.group_list {
  margin-top: 0px;
}
.group_footer {
  margin-top: -21px;
 
}
.add_to_group {
  margin-top: auto;
  // height: 52px;
  padding-top: 30px;
}
.select_group_type {
  border: none;
  &:hover {
    outline: none;
    border: none;
  }
  background: #e3f1ff;
  border-radius: 10px;
  padding: 10px 15px;
}
.succes_message {
  height: 50px;
  border-radius: 10px;
  padding: 13px;
  background: #d6fcc4;
  position: absolute;
    bottom: 24px;
}
 
</style>
