<template>
    <header class="header">
        <div class="header-content">
            <div class="header-item d-flex align-items-center">
                <div class="site-logo-icon">
                    <router-link to="/messages">
                        <SiteLogoIcon/>
                    </router-link>
                </div>
                <h4 class="dashboard-title">Operator Console <span class="beta-mode">/ Beta</span></h4>
            </div>
            <div class="header-item menu-item" :class="{'show': isMenuOpen}">
                <nav class="navbar justify-content-center">
                    <router-link to="/contacts"
                                class="nav-link d-flex align-items-center"
                                :class="{ 'active': $route.path === '/contacts' || $route.path.startsWith('/contacts/') }">
                    Contacts
                    </router-link>
                       <router-link to="/group"
                                class="nav-link d-flex align-items-center"
                                :class="{ 'active': $route.path === '/group' || $route.path.startsWith('/group/') }">
                    Groups
                    </router-link>
                    <router-link to="/messages"
                                class="nav-link d-flex align-items-center"
                                :class="{ 'active': $route.path === '/messages' || $route.path.startsWith('/messages/') }">
                    Messages
                    </router-link>
                 
                </nav>
                
                <div class="logout-links-con d-flex d-md-none align-items-center justify-content-center">
                    <h4 class="username"  @click="TogglePopup('buttonTrigger')">{{user}}</h4>
                    <div class="logout-icon" @click="logout();">
                        <LogOutIcon/>
                    </div>
                </div>
            </div>
            <div class="header-item d-none d-md-flex align-items-center justify-content-end">
                <h4 class="username"  @click="TogglePopup('buttonTrigger')">{{user}}</h4>
                <div class="logout-icon icon-btn" @click="logout();">
                    <LogOutIcon/>
                </div>
            </div>
            
            <button class="btn btn-toggle d-block d-md-none" @click="toggleMenu">
                <MenuIcon/>
            </button>
        </div>
        <UpdateProfile 
            v-if="popupTriggers.buttonTrigger"  :title="'Operator'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>ProfileSaved()" >
        </UpdateProfile>
    </header>
</template>
<script>
import SiteLogoIcon from '@/components/icons/SiteLogoIcon.vue';
import LogOutIcon from '@/components/icons/LogOutIcon.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import UpdateProfile from '../UpdateProfile'; 
import { ref } from 'vue';

export default {
    name: 'Header',
    props: ['user'],
    components: { SiteLogoIcon, LogOutIcon, MenuIcon,UpdateProfile },
    setup () {
        const popupTriggers = ref({
            buttonTrigger: false
        });		
        const TogglePopup = (trigger) => {
            
            popupTriggers.value[trigger] = !popupTriggers.value[trigger]
        }
        return {
            UpdateProfile,
            popupTriggers,
            TogglePopup,
        }
    },
    data() {
        return {
            isMenuOpen: false
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        isActive(path) {
            return ref(this.$router.currentRoute).value.path === path
        },
        logout(){
            this.$root.logout();
        },
        ProfileSaved(){
            this.popupTriggers.buttonTrigger = false;
        }
    }
}
</script>
<style lang="scss" scoped>
 .header{
     width: 100%;
     padding: 15px 25px 15px 15px;
     background: #F2F2F2;
     position: relative;
     min-height: 80px;
     .header-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .site-logo-icon{
        margin-right: 25px;
        width: 50px;
        height: 50px;
    }
    .header-item{
        min-width: 215px;
        h4{
            color: #000;
            font-family: "Roboto";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 80%;
            & .beta-mode {
                font-size: 15px;
                line-height: 16px;
                font-weight: 400;
                color: #7B8794;
            }
            &.username{
                padding: 7px 6px;
                font-weight: 400;
                cursor: pointer;
                &:hover{
                    background: #fff;
                    border-radius: 8px;
                }
            }
        }
    }
    .menu-item{
        @media screen and (max-width: 960px) {
            display: none;
            position: absolute;
            z-index: 9;
            width: 100%;
            top: 100%;
            left: 0;
            background: #ffffff;
            padding: 30px;
            box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
            &.show{
                display: block;
            }
            .navbar{
                flex-direction: column;
            }
            .logout-links-con{
                margin-top: 30px;
            }

        }
    }
    .logout-icon{
        margin-left: 8px;
    }
    .btn-toggle{
        outline: none;
        opacity: 0.7;
        &:active, &:focus{
            outline: none;
            box-shadow: none;
        }
    }
    .navbar{
        padding: 0;
        display: flex;
        align-items: center;
        gap: 36px;
        .nav-link{
            padding: 8px 0 4px;
            display: inline-flex;
            align-items: flex-start;
            color: #000;
            text-align: center;
            font-family: "Roboto";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            border-bottom: 4px solid transparent;
            &.active, &:hover{
                border-bottom-color: #000;
            }
        }
        @media screen and (max-width: 768px) {
            gap: 30px;
        }
    }
 }
</style>