<template>
    <div class="text-center">
      <v-pagination
        v-model="localPage"
        :length="totalPages"
        :total-visible="itemsPerPage"
        :update="$emit('page-changed', localPage)"
      ></v-pagination>
    </div>
  </template>
  
<script>
import { VPagination } from 'vuetify';
export default {
  props: ['currentPage', 'totalItems', 'itemsPerPage', 'totalPages'] ,
  components: {
    VPagination
  },
  data() {
    return {
      localPage: this.currentPage,
    };
  },
  computed: {
    /* totalPages() {
      if (this.itemsPerPage <= 0) return 0;
      return Math.ceil(this.totalItems / this.itemsPerPage);
    } */
  },
      watch: {
         
        'currentPage': {          
            immediate: true,
            handler(val) {         
                this.localPage = val 
            }
        },
       
  
    }
};
</script>
<style lang="scss">
  .v-pagination__first, .v-pagination__item, .v-pagination__last, .v-pagination__next, .v-pagination__prev {
      margin: 0.3rem;
      height: 34px;
      width: 34px;
  }
  .v-pagination .v-btn {
    border-radius: 4px;
    height: 100% !important;
    width: 100% !important;
  }
  .v-pagination__item--is-active .v-btn{
    background-color: #6c757d!important;
    border-color: #6c757d!important;
    color:white !important;
    font-size: 1.07rem !important;
  }
    /* .v-pagination > li{
        button{
            background: transparent !important;
            box-shadow: none !important;
            &:hover{
                background-color: #e3e3e3 !important;
            }
            &.primary{
                background-color: #6c757d !important;
                border-color: #6c757d !important;
            }
        }
        .v-pagination__navigation{
            box-shadow: none !important;
            &:hover{
                background-color: #e3e3e3 !important;
            }
        }
    } */
</style>