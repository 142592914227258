<template>
    <v-dialog class="logged-out-dialog" v-model="dialog"  persistent  max-width="450">
        <v-card >
            <v-toolbar class="modal-header loggedout-modal">
                <v-toolbar-title class="loggedout-modal">You have logged out.</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="card-text-con">
                <div class="about-card-text loggedout-modal">
                    <span>Thank you for using Sig2. </span><br/><br/>
                    We want to give everyone access to Geofence Messaging so they can make their 
                    communication more efficient.<br/><br/>
                    Share Sig2 with someone who will find it useful.
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <button @click="goToLogin" class="close-btn btn text-btn ms-auto">Close</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { VDialog, VCard,VToolbarTitle,VToolbar, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'LoggedoutModal',
props: {
    value: {
        type: Boolean,
        default: false
    }
},
components : {
    VDialog,   VCard, VCardText, VToolbar, VToolbarTitle, VCardActions  
},
computed: {
    dialog: {
        get() {
            return this.value;
        },
        set(value) {
            this.$emit('input', value);
        }
    }
},
methods: {
    goToLogin(){
      location.reload()
    }
}
};
</script>
<style lang="scss" >
    .modal-header.loggedout-modal{
        background: #344D7A !important;
       
        & .v-toolbar-title__placeholder {
            color: #fff;
        }
    }
    .card-text-con{
         padding: 25px 10px 0 16px !important;
         .about-card-text.loggedout-modal{
             color: #000;
             font-size: 16px;
             font-style: normal;
             font-weight: 400;
             line-height: 20px;
             span{
                 font-size: 18px;
             }
         }
    }
    
    .loggedout-modal .v-toolbar-title__placeholder{
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
    }
</style>