<template>
    
      <v-dialog
        v-model="dialog"
        persistent
        class="custom-message-settings"
      >
        <v-card>
          
          <v-card-title>
            <span class="dialog-title">Group Selection</span>
          </v-card-title>
          <button type="button"
              class="close-btn-icon"
              @click="onCloseWithoutSave"
            >
            <span class="outline">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>

            </span>
            <span class="outline_hover">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            </button>
          <v-card-text>
             <div class="group-selection-card">
                <GroupListForMessages 
                ref="groupList" 
                :isSelectAllGroups="isSelectAllGroups" 
                :selectedGroupsIds="selectedGroupsIds"  
                :groupTypesList="groupTypesList"
                @groups-selected="onGroupSelected" />
              </div>

            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              class="close-btn text-btn btn"
              variant="text"
              @click="onCloseWithoutSave"
            >
              Cancel
            </v-btn>
            <v-btn
              :ripple="false"
              color="blue-darken-1"
              variant="text"
              :disabled="!enableSaveButton"
              @click="onSaveSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
  </template>

<script setup>
    import { ref, defineEmits, defineProps, computed, watch, onMounted } from 'vue';
    import GroupListForMessages from "@/components/group/GroupListForMessages.vue";
    import { useRoute, useRouter } from 'vue-router';
    let enableSaveButton = ref(true);

    let props = defineProps({
        showSettings:Boolean,
        selectedGroupsIds:Array,
        isSelectAllGroups:Boolean,
        groupTypesList:Array
    })

    const emits = defineEmits(['closed', 'setting-saved']);
    const router = useRouter();
    const route = useRoute();
    let data = {};
    
    let dialog = computed({
            get() {
                return props.showSettings;
            },
            set(value) {emits('closed', value);}
    })
   
        
        let onGroupSelected = function(selection){
            enableSaveButton.value = selection.checkedItems.length > 0;
            data = selection;
        }

        let onSaveSettings = function(){
            emits('group-selection-saved', data)
            dialog.value = false;
        }

        
        let onCloseWithoutSave = function(){
            dialog.value = false;
        }

</script>


<style lang="scss" >
  .group-selection-card {
    padding: 19px;
    max-width: 644px;
    margin: 0 auto;
    width: 100%;
  }
   .card-text-con{
        padding: 25px 10px 0 16px !important;
        .about-card-text{
            color: #000;
            font-family: 'Roboto';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 114.286%;
            .nav-link{
                color: #344D7A;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }
        }
   }

   .custom-message-settings .form-control {
        padding: 5px 13px !important;
        height: 44px !important;
        border-radius: 5px !important;
        border: 1px solid #BDBDBD !important;
        background: #FFF !important;
    }
    .custom-message-settings .dropdown-btn{
        width: auto !important;
        min-width: 130px !important;
    }
   
   .v-toolbar-title .v-toolbar-title__placeholder{
        color: #000;
        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: .01em;
   }
   .range-slider-tow{
        border-bottom: 1px solid #BDBDBD;
   }
   .dialog-title{
        font-size: 16px !important;
        font-weight: 600 !important;
        color:#000;
   }
    .custom-message-settings .v-card-text {
        padding: 0px 0px 0px !important;
    }
    .custom-message-settings .v-card{
        padding: 0px 15px !important;
    }
   
    .custom-radio-selected{
        margin-left: 25px;
       /*  padding:20px; */
        border-radius: 5px;
        background-color: #F7F7F7;
        border:1px solid #CCCCCC;
    }
    .custom-radio-not-selected{
        margin-left: 25px;
       /*  padding:20px; */
        border-radius: 10px;
        background-color: none;
        border:1px solid transparent;
    }
    .custom-message-settings .v-card-actions{
        margin-top:0px !important;
    }
    .custom-message-settings .v-container{
        padding-bottom:0px !important;
    }
    .custom-date-picker {
        transform: scale(0.8);
        transform-origin: top left;
        border: 1px solid gray !important;
    }
    .v-locale--is-ltr .v-slider.v-input--horizontal .v-slider-thumb__label, .v-locale--is-ltr.v-slider.v-input--horizontal .v-slider-thumb__label {
        background: white !important;
        border-radius: 5px !important;
        border:1px solid #7B8794 !important;
        color:#7B8794;
        height:30px !important;
    }
    .custom-callout-range-slider{
        font-size:11px;
        color: #7B8794;
        font-weight: 500; 
        text-align:center; 
        white-space:nowrap;
        padding:5px 0px;
    }
    .v-slider-thumb__surface{
        background: white !important;
        border:none !important;
    }
    .a-slider .v-slider-thumb{
        border:3px solid #828282 !important;
    }
    .v-slider-track__tick-label{
        font-size: 12px !important;
    }
    .v-date-picker{
        border:1px solid #969494;
    }
    .custom-right-label{
        padding-left:60px !important;
        line-height: 40px !important;
    }
    .v-date-picker-month__day--hide-adjacent {
        //opacity: 0;
        display: none !important;
    }
    .custom-message-settings .custom-label{
        font-size: 16px !important;
        font-weight: 600 !important;
        color : #000 !important;
    }

/*     ::v-deep { */
  .a-slider {
    .v-slider-thumb {
      touch-action: none;
      color: rgb(255 251 251);
      border: 3px solid #424242;
      border-radius: 15px;
      &__label {
        background: #ffffff !important;
        color: #000000 !important;
        border: 2px solid #424242 !important;
        min-width: 62px !important;
        height: 30px !important;
      }
      &:nth-of-type(2) {
        .v-slider-thumb {
          &__label {
            top: -45px;
            &:after {
              content: "";
              position: absolute;
              top: 34px;
              width: 18px;
              height: 1px;
              background-color: #7B8794;
              /* 1px solid #7B8794 !important */
            }
            &:before {
              content: "";
              bottom: -12px !important;
              width: 14px;
              height: 18px;
              border-top: 0;
              background-color: #fff;
            }
          }
        }
      }
      &:nth-of-type(3) {
        .v-slider-thumb {
          &__label {
            top: -45px;
            &:after {
              content: "";
              position: absolute;
              top: 34px;
              width: 18px;
              height: 1px;
              background-color: #7B8794;
            }
            &:before {
              content: "";
              bottom: -12px !important;
              width: 14px;
              height: 18px;
              border-top: 0;
              background-color: #fff;
            }
          }
        }
      }
    }

    &.flip {
      .v-slider-thumb {
        &:nth-of-type(2) {
          .v-slider-thumb {
            &__label {
              left: -30px;
              &:before {
                right: -1px !important;
                border-right: 1px solid #7B8794;
              }
              &:after {
                left: 44px;
                rotate: -139deg;
              }
            }
          }
        }
        &:nth-of-type(3) {
          .v-slider-thumb {
            &__label {
              right: -90px;
              &:before {
                left: -1px !important;
                border-left: 1px solid #7B8794;
              }
              &:after {
                right: 44px;
                rotate: 139deg;
              }
            }
          }
        }
      }
    }

    &.unflip {
      .v-slider-thumb {
        &:nth-of-type(2) {
          .v-slider-thumb {
            &__label {
              left: 30px;
              &:before {
                left: -1px !important;
                border-left: 1px solid #7B8794;
              }
              &:after {
                right: 44px;
                rotate: 139deg;
              }
            }
          }
        }
        &:nth-of-type(3) {
          .v-slider-thumb {
            &__label {
              left: -30px;
              &:before {
                right: -1px !important;
                border-right: 1px solid #7B8794;
              }
              &:after {
                left: 44px;
                rotate: -139deg;
              }
            }
          }
        }
      }
    }
  }
/* } */
    
</style>