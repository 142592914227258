<template>
  <div class="router-view-wrapper" v-if="showlogin">
    <div class="landing-page app-container d-flex justify-content-between">
      <div class="landing-text-section">
        <h2 class="font-roboto text-primary">Right Place. Right Time.</h2>
        <h4 class="font-roboto text-primary fw-medium ">Geofence Text-to-Speech Messages</h4>
      </div>
      <div class="landing-login-form-section">
        <Auth/>
      </div>
    </div>
    <Footer :classValue="'home-footer'"/>
  </div>
</template>

<script>
import Auth from '../components/Auth.vue'
import Footer from '../components/common/Footer.vue'
import { ref } from 'vue';
export default {
  name: 'HomeView',
  components: { Auth, Footer },
  data() {
    return {
      showlogin: false
    }
	},
  created(){
    
    setTimeout(()=>{
      this.showlogin = ref(this.$router.currentRoute).value.path == '/auth'
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>

.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.landing-page{
  padding-top: 70px;
  padding-bottom: 70px;
  @media screen and (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: column;
    min-height: max-content;
    gap: 30px;
  }
  @media screen and (max-width: 568px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .landing-text-section{
    h2{
      font-size: 64px;
      line-height: 72px;
      font-weight: 800;
      @media screen and (max-width: 1280px) {
        font-size: clamp(28px,5vw, 56px);
      }
    }
    h4{
      font-size: 42px;
      line-height: 72px;
      @media screen and (max-width: 1440px) {
        font-size: 42px;
      }
      @media screen and (max-width: 1280px) {
        font-size: clamp(18px, 3.2vw, 40px);
      }
    }
  }
}
</style>